
import { defineComponent, onMounted, ref, reactive } from "vue";
// import { defineComponent, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import Loading from "vue-loading-overlay";
import Multiselect from "@vueform/multiselect";

interface NewData {
  file: string;
  file2: string;
  file3: string;
  documentType: string;
  idSchool: string;
  fileName: string;
}

interface items {
  legalType: any;
  listSchool: any;
  fileInputKey: any;
  errorschoolId: string;
  errorType: string;
  errorFile: string;
  errorFile2: string;
  errorFile3: string;
}

export default defineComponent({
  name: "new-target-modal",
  components: {
    Loading,
    Multiselect,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      legalType: [],
      listSchool: [],
      fileInputKey: 0,
      errorschoolId: "",
      errorType: "",
      errorFile: "",
      errorFile2: "",
      errorFile3: "",
    });

    const targetData = reactive<NewData>({
      file: "",
      file2: "",
      file3: "",
      documentType: "",
      idSchool: "",
      fileName: "",
    });

    function handleFileUpload(event) {
      targetData.file = event.target.files[0];
    }

    function handleFileUpload2(event) {
      targetData.file2 = event.target.files[0];
    }

    function handleFileUpload3(event) {
      targetData.file3 = event.target.files[0];
    }

    const getListSchool = () => {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          items.listSchool = data;
          // localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
      // items.listSchool = JSON.parse(localStorage.getItem("listSchool")!);
    };

    const getLegalType = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_legal/admin_school/legal/combo/type"
      ).then((response) => {
        items.legalType = response.data;
        console.log(items.legalType);
      });
    };

    const submit = () => {
      if (targetData.documentType === "PKS") {
        submitPKS();
      } else {
        submitNoPKS();
      }
    };

    const submitPKS = () => {
      let user_account = JSON.parse(localStorage.getItem("user_account")!);
      targetData.idSchool = user_account.schoolId;

      if (
        targetData.idSchool === "" ||
        targetData.file === "" ||
        targetData.file2 === "" ||
        targetData.file3 === "" ||
        targetData.documentType === ""
      ) {
        if (targetData.idSchool === "") {
          items.errorschoolId = "Sekolah Wajib Diisi";
        }
        if (targetData.file === "") {
          items.errorFile = "File Wajib Diisi";
        }
        if (targetData.file2 === "") {
          items.errorFile2 = "File Wajib Diisi";
        }
        if (targetData.file3 === "") {
          items.errorFile3 = "File Wajib Diisi";
        }
        if (targetData.documentType === "") {
          items.errorType = "Tipe Wajib Diisi";
        }
        toast.error("Form belum lengkap");
      } else {
        isLoading.value = true;
        items.errorschoolId = "";
        items.errorFile = "";
        items.errorFile2 = "";
        items.errorFile3 = "";
        items.errorType = "";

        loading.value = true;

        loading.value = false;
        let formData = new FormData();

        formData.append("documentFile", targetData.file);
        formData.append("documentSchoolOperational", targetData.file2);
        formData.append("documentSkPengurus", targetData.file3);

        ApiService.postWithData(
          "crmv2/main_legal/admin_school/legal/create_and_upload_document_legality/" +
            targetData.idSchool +
            "?documentType=" +
            targetData.documentType,
          formData
        )
          .then((res) => {
            toast.success("Berhasil Upload Dokumen");
            targetData.file = "";
            targetData.file2 = "";
            targetData.file3 = "";
            targetData.documentType = "";
            targetData.idSchool = "";
            targetData.fileName = "";
            items.fileInputKey++;
            hideModal(uploadModalRef.value);
            isLoading.value = false;

            router.push({
              path: "/sekolah/legal/legalitas",
            });
            location.reload();
          })
          .catch((e) => {
            isLoading.value = false;
            toast.error(e.response.data.detail);
          });
      }
    };

    const submitNoPKS = () => {
      let user_account = JSON.parse(localStorage.getItem("user_account")!);
      targetData.idSchool = user_account.schoolId;

      if (
        targetData.idSchool === "" ||
        targetData.file === "" ||
        targetData.documentType === ""
      ) {
        if (targetData.idSchool === "") {
          items.errorschoolId = "Sekolah Wajib Diisi";
        }
        if (targetData.file === "") {
          items.errorFile = "File Wajib Diisi";
        }
        if (targetData.documentType === "") {
          items.errorType = "Tipe Wajib Diisi";
        }
        toast.error("Form belum lengkap");
      } else {
        isLoading.value = true;
        items.errorschoolId = "";
        items.errorFile = "";
        items.errorType = "";

        loading.value = true;

        loading.value = false;
        let formData = new FormData();

        formData.append("documentFile", targetData.file);

        ApiService.postWithData(
          "crmv2/main_legal/admin_school/legal/create_and_upload_document_legality/" +
            targetData.idSchool +
            "?documentType=" +
            targetData.documentType,
          formData
        )
          .then((res) => {
            toast.success("Berhasil Upload Dokumen");
            targetData.file = "";
            targetData.documentType = "";
            targetData.idSchool = "";
            targetData.fileName = "";
            items.fileInputKey++;
            hideModal(uploadModalRef.value);
            isLoading.value = false;

            router.push({
              path: "/sekolah/legal/legalitas",
            });
            location.reload();
          })
          .catch((e) => {
            isLoading.value = false;
            toast.error(e.response.data.detail);
          });
      }
    };

    const backEvent = () => {
      targetData.file = "";
      targetData.documentType = "";
      targetData.idSchool = "";
      targetData.fileName = "";
      items.fileInputKey++;

      items.errorschoolId = "";
      items.errorType = "";
      items.errorFile = "";
      items.errorFile2 = "";
      items.errorFile3 = "";
    };

    onMounted(() => {
      getLegalType();
    });

    return {
      targetData,
      submit,
      backEvent,
      loading,
      formRef,
      items,
      uploadModalRef,
      handleFileUpload,
      handleFileUpload2,
      handleFileUpload3,
      getLegalType,
      getListSchool,

      submitPKS,
      submitNoPKS,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
